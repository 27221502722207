import http from '@/libs/http'
import { useStore } from '@/store'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_INFORMES + '/inventarios'
export default class InventariosServices {
  stockMx (params) {
    return http.get(`${baseUrl}/stock-mx`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  downloadStockMx (params) {
    const store = useStore()
    const token = store.getters['auth/getToken']
    params.token = `Bearer ${token}`
    return http.getUri({ url: `${baseUrl}/download-stock-mx`, params })
  }
}
